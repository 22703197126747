/**
 * @file HOC for fetching global data.
 */
import { keyBy } from 'lodash';
// import { isEmpty } from 'lodash';

import fetch, { fetchClosureForSSR } from '../data/restApi/fetchApi';
// import Loader from '../app/components/Loader';
import GlobalProvider from '../app/components/GlobalProvider';
import persistentState, { PERSISTENT_STATE_KEYS } from '../app/utils/persistentState';

const DATA = 0;
const SWR_KEY = 1;

const PageWithSwrMiddleware = props => {
  const { pageProps, Component } = props;

  const fetchOptions = {
    middleware: data => ({
      data: data,
      tagsBySlug: keyBy(data.tags, 'slug'),
    }),
  };

  if (
    pageProps?.swrFallback &&
    Object.values(pageProps?.swrFallback?.info)?.[0]
  ) {
    fetchOptions.fallback = pageProps.swrFallback.info;
  }

  const { data: infoData } = fetch.info.withSWR(fetchOptions);

  // if (isEmpty(infoData)) return <Loader fullPage globalStateLoader />;

  return (
    <GlobalProvider
      userData={pageProps?.swrFallback?.userData || pageProps?.userData}
    >
      <Component {...pageProps} global={infoData} />
    </GlobalProvider>
  );
};

const withGlobalData = Component => {
  return props => (
    <PageWithSwrMiddleware pageProps={props} Component={Component} />
  );
};

export const addStaticProps = async ({
  locale,
  referer,
  cookie,
  jwt,
  previewProps,
  medical,
}) => {
  let userDataPromise;

  console.log('###hoj', cookie);

  const fetchForSSR = fetchClosureForSSR({
    predefinedProps: {
      language: locale,
      referer,
      cookie,
      jwt,
      medical,
    },
    predefinedQuery: previewProps,
  });

  const infoDataPromise = fetchForSSR({
    subject: 'info',
  });

  if (cookie) {
    userDataPromise = fetchForSSR({
      subject: 'user',
      query: {
        token: process.env.PRUSUKI_API_TOKEN,
      },
    });
  }

  let [userData, infoData] = await Promise.all([
    userDataPromise,
    infoDataPromise,
  ]);

  const userCookie = persistentState.cookie.get({
    key: PERSISTENT_STATE_KEYS.user,
    cookies: cookie,
  });

  const infoDataProcessed = infoData[DATA];

  if (infoDataProcessed) {
    infoDataProcessed.isMedical = medical || null;
  }

  return {
    userData: userData?.[DATA] || userCookie || null,
    info: {
      [infoData[SWR_KEY]]: infoDataProcessed || null,
    },
  };
};

export default withGlobalData;
